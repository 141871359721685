import React from 'react';
import css from './ManageSubscriptionPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';
import Button from '../Button/Button';
import { cancelStripeSubscription } from '../../util/api';
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_CANCELLED } from '../../util/types';
import { firstLetterCapital } from '../../util/helper';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import IconCard from '../IconCard/IconCard';

const ManageSubscriptionPanel = ({ history,currentUser,getStatusData }) => {
  const routeConfiguration = useRouteConfiguration();
  
 // Filter active or cancelled subscriptions
 const onlineSubscriptionData = getStatusData?.find(
  item => item.attributes.status === SUBSCRIPTION_STATUS_ACTIVE || item.attributes.status === SUBSCRIPTION_STATUS_CANCELLED
)?.attributes || {};
const id = getStatusData.find(item => item.attributes.status === 'active')?.id;
  const isSubscriptionActive = onlineSubscriptionData?.status === SUBSCRIPTION_STATUS_ACTIVE;
  const isSubscriptionCancelled = onlineSubscriptionData?.status === SUBSCRIPTION_STATUS_CANCELLED;
  const subscriptionId = onlineSubscriptionData?.subscription_id;
  const customerId = currentUser?.id?.uuid || '';

  const subscriptionEndDate = currentUser?.attributes?.profile?.protectedData?.freePlanData?.endDate
  ? moment.unix(currentUser.attributes.profile.protectedData.freePlanData.endDate)
  : null;
const formattedEndDate = subscriptionEndDate?.format('YYYY-MM-DD');
  
  const currentSubscription =
  onlineSubscriptionData &&
  onlineSubscriptionData?.status === SUBSCRIPTION_STATUS_ACTIVE || onlineSubscriptionData.status === SUBSCRIPTION_STATUS_CANCELLED;
  const subData = currentSubscription;
  const isPlanCancelled = currentSubscription && currentSubscription?.cancel_at_period_end;

  const subscriptionomplete = onlineSubscriptionData && onlineSubscriptionData?.status == SUBSCRIPTION_STATUS_ACTIVE;

  // Get the subscription status in a readable format
  const getSubscriptionStatus = () => {
    if (isSubscriptionActive) {
      return firstLetterCapital(SUBSCRIPTION_STATUS_ACTIVE);
    }
    if (isSubscriptionCancelled) {
      return 'Cancelled';
    }
    return 'Inactive';
  };

  const subscriptionStatus = getSubscriptionStatus();

  // Handle subscription cancellation
  const handleCancelSubscription = async (subscriptionId,customerId,id) => {
    try {
      const response = await cancelStripeSubscription({ subscriptionId,customerId,id });
      if (response?.data?.attributes?.status == 'inActive') {
        history.push(createResourceLocatorString('StripeSubscriptionPage', routeConfiguration, {}, {}));
      }
    } catch (err) {
      console.error('Error canceling subscription:', err);
    }
  };

  // Format price
  const formatPrice = priceInCents => (priceInCents / 100).toFixed(2);

  return (
    <>
      {getStatusData ? (
        <div className={css.manageSubscriptionPanel}>
          <div className={css.headingTopBoxs}>
            <div className={css.billingInformationTitle}>
              <FormattedMessage id="ManageSubscriptionPanel.billingInformation" />
            </div>

            <div className={css.verifyBox}>
              <IconCard brand="checkicon" />
              <span> {subscriptionomplete && "Congratulations on successfully subscribing to the Pro Star!"}</span>
            </div>
          </div>
          <div className={css.planWrapper}>
            
            <div className={css.planCard}>
              <div className={css.wrapperBox}>
                <div className={css.planHeading}>
                  <div className={css.planLeftSide}>
                    <div className={css.planHeadingBox}>
                      {onlineSubscriptionData.status == 'active' && <h3>Unlock the benefits of Pro Stars today</h3>}
                      <span
                        className={
                          !onlineSubscriptionData?.status == 'active'
                            ? css.inActiveBadge
                            : css.activeBadge
                        }
                      >
                        {subscriptionStatus}
                      </span>
                     
                    </div>
                    <p className={css.planDescription}>
                      Best for merchnat that need to sell or marketing their products on myFindor marketplace
                    </p>
                  </div>
                  <div className={css.planRightSide}>
                    <div className={css.planPrice}>
                      <span className={css.price}>${formatPrice(onlineSubscriptionData?.price || 0)}</span>
                      <span className={css.planPeriod}>
                        /month
                      </span>
                    </div>
                  </div>
                </div>
                <div className={css.teamInformation}></div>
              </div>
              <div className={css.planBody}>
                {isSubscriptionActive && (
                  <div className={css.planBodyRight}>
                    <Button
                      onClick={() => handleCancelSubscription(subscriptionId,customerId,id)}
                      className={css.subscriptionButton}
                    >
                      <FormattedMessage id="ManageSubscriptionPanel.cancelSubscriptionButton" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h3 className={css.nameHeading}>
            <FormattedMessage id="ManageSubscriptionPanel.billingHistory" />
          </h3>
          <div className={css.tableBox}>
            <table className={css.tableStriped}>
              <thead>
                <tr className={css.subscriptionHeading}>
                  <th className={css.leftTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.planHeading" />
                  </th>
                  <th className={css.centerTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.billingDetailsHeading" />
                  </th>
                  <th className={css.rightTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.amountHeading" />
                  </th>
                </tr>
              </thead>
              <tbody>
              {isSubscriptionActive ? (
                  <tr>
                    <td>Unlock the benefits of Pro Stars today</td>
                    <td>{moment(onlineSubscriptionData?.current_period_start * 1000).format('MMM Do YYYY')}</td>
                    <td>${formatPrice(onlineSubscriptionData?.price)}</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="3">
                      <FormattedMessage id="ManageSubscriptionPanel.noSubscriptionData" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        
      ) : (
        <div className={css.loaderBox}><SkeletonLoader /></div>
      )}
    </>
  );
};

export default ManageSubscriptionPanel;
